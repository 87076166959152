import {
  TravisBackendDataWhatsappCloudApiWhatsappCloudApiTemplateResponse,
  TravisBackendMessageDomainViewModelsWhatsapp360DialogTemplateMessageViewModel,
  TravisBackendMessageDomainViewModelsWhatsappCloudApiTemplateMessageViewModel,
} from '@sleekflow/sleekflow-core-typescript-rxjs-apis';
import {
  SleekflowApisMessagingHubModelWhatsappCloudApiParameterObject,
  TravisBackendChannelDomainViewModelsWhatsAppBusinessApiTemplateViewModel,
} from '@sleekflow/sleekflow-core-typescript-rxjs-apis/dist/models';
import { WABA360DialogApiClientPayloadsModelsMessageObjectsParameterObject } from '@sleekflow/sleekflow-core-typescript-rxjs-apis/dist/models/WABA360DialogApiClientPayloadsModelsMessageObjectsParameterObject';

import { MATCH_DOUBLE_CURLY_BRACKET_CONTENT_REGEX } from '@/pages/Broadcasts/BroadcastCreateChannel/shared/utils';
import { MessagingChannel } from '@/services/companies/company.service';
import { ConversationMessageWrapperMessage } from '@/services/conversation-messages/managers/conversation-message-wrapper';
import { exhaustiveGuard } from '@/utils/ts-utils';

export function replaceTemplateTextWithTemplateParameters({
  parameters,
  text,
}: {
  text: string;
  parameters:
    | (
        | SleekflowApisMessagingHubModelWhatsappCloudApiParameterObject
        | WABA360DialogApiClientPayloadsModelsMessageObjectsParameterObject
      )[]
    | null
    | undefined;
}) {
  const DOUBLE_CURLY_BRACKET_REGEX = /({{[^{|}]+}})/gm;
  const splitByVariable = text?.split(/({{[^{|}]+}})/gm) || [];
  const variableArray = splitByVariable.filter((str) =>
    DOUBLE_CURLY_BRACKET_REGEX.test(str),
  );

  if (parameters?.length === variableArray.length) {
    const result = splitByVariable?.reduce<string[]>((acc, nextVal) => {
      if (DOUBLE_CURLY_BRACKET_REGEX.test(nextVal)) {
        // strip the brackets and use as index
        const index = Number(nextVal?.replace('{{', '').replace('}}', ''));
        if (parameters[index - 1]) {
          acc.push(parameters[index - 1].text!);
        } else {
          throw new Error(
            'the number of variables in the template is different to the number in extended payload',
          );
        }
      } else {
        acc.push(nextVal);
      }

      return acc;
    }, []);

    return result.join('');
  } else {
    throw new Error(
      'the number of variables in the template is different to the number in extended payload',
    );
  }
}

export function mapWhatsAppCloudAPITemplateMessageToParams({
  template,
  whatsappCloudApiTemplateMessageObject,
}: {
  template: TravisBackendDataWhatsappCloudApiWhatsappCloudApiTemplateResponse;
  whatsappCloudApiTemplateMessageObject: TravisBackendMessageDomainViewModelsWhatsappCloudApiTemplateMessageViewModel;
}) {
  const templateComponents = template.components;
  const whatsappCloudApiTemplateMessageObjectComponents =
    whatsappCloudApiTemplateMessageObject.components;

  const components = templateComponents?.map((component) => {
    if (!whatsappCloudApiTemplateMessageObjectComponents) {
      return component;
    }

    const foundComponentInMessageObject =
      whatsappCloudApiTemplateMessageObjectComponents.find(
        (whatsappCloudApiTemplateMessageObjectComponent) =>
          whatsappCloudApiTemplateMessageObjectComponent.type ===
          component.type?.toLowerCase(),
      );

    if (!foundComponentInMessageObject) {
      return component;
    }

    const templateText = component.text;
    const isBodyComponent =
      component.type === 'BODY' &&
      foundComponentInMessageObject.type === 'body' &&
      templateText;

    if (isBodyComponent) {
      const parameters = foundComponentInMessageObject.parameters;

      return {
        ...component,
        text: replaceTemplateTextWithTemplateParameters({
          parameters,
          text: templateText,
        }),
      };
    }
    // TODO: dynamic url replacement
    return component;
  });
  return {
    ...template,
    components,
  };
}

export function mapWhatsApp360DialogTemplateMessageToParams({
  template,
  whatsapp360DialogTemplateMessage,
}: {
  template: TravisBackendChannelDomainViewModelsWhatsAppBusinessApiTemplateViewModel;
  whatsapp360DialogTemplateMessage: TravisBackendMessageDomainViewModelsWhatsapp360DialogTemplateMessageViewModel;
}) {
  const templateComponents = template.components;
  const whatsappCloudApiTemplateMessageObjectComponents =
    whatsapp360DialogTemplateMessage.components;

  const components = templateComponents?.map((component) => {
    if (!whatsappCloudApiTemplateMessageObjectComponents) {
      return component;
    }

    const foundComponentInMessageObject =
      whatsappCloudApiTemplateMessageObjectComponents.find(
        (whatsappCloudApiTemplateMessageObjectComponent) =>
          whatsappCloudApiTemplateMessageObjectComponent.type ===
          component.type?.toLowerCase(),
      );

    if (!foundComponentInMessageObject) {
      return component;
    }

    const templateText = component.text;
    const isBodyComponent =
      component.type === 'BODY' &&
      foundComponentInMessageObject.type === 'body' &&
      templateText;

    if (isBodyComponent) {
      const parameters = foundComponentInMessageObject.parameters;

      return {
        ...component,
        text: replaceTemplateTextWithTemplateParameters({
          parameters,
          text: templateText,
        }),
      };
    }
    return component;
  });
  return {
    ...template,
    components,
  };
}

export function mapWhatsappTemplateContentVariables(
  text: string,
  contentVariables: Record<string, string | undefined | null>,
) {
  let mappedBody = text;

  let match;
  while (
    (match = MATCH_DOUBLE_CURLY_BRACKET_CONTENT_REGEX.exec(mappedBody)) !== null
  ) {
    const key = match[1].trim();
    const value = contentVariables[key];

    if (typeof value === 'string') {
      mappedBody = mappedBody.replace(match[0], value);
    }
  }

  return mappedBody;
}

export const findMessagingChannelsFromFilters = (
  { messagingChannels }: { messagingChannels: MessagingChannel[] },
  filters: MessagingChannel,
) => {
  return messagingChannels.filter((channel) => {
    return Object.keys(filters).every((key) => {
      if (filters[key as keyof MessagingChannel]) {
        return (
          channel[key as keyof MessagingChannel] ===
          filters[key as keyof MessagingChannel]
        );
      }
      // if filter value is undefined ignore comparing
      return true;
    });
  });
};

export const transformConversationMessageWrapperMessagingChannelToMessagingChannel =
  (
    conversationWrapperMessagingChannel: ConversationMessageWrapperMessage['messagingChannel'],
  ) => {
    const channelType = conversationWrapperMessagingChannel?.channelType;
    if (!channelType) {
      return undefined;
    }
    switch (channelType) {
      case 'whatsapp':
        return {
          channelType: 'whatsapp' as const,
          twilioAccountId: conversationWrapperMessagingChannel?.instanceId,
          channelIdentityId:
            conversationWrapperMessagingChannel.channelIdentityId,
        };
      case 'whatsappcloudapi':
        return {
          channelType: 'whatsappcloudapi' as const,
          whatsappPhoneNumber:
            conversationWrapperMessagingChannel.whatsappChannelPhoneNumber,
          channelIdentityId:
            conversationWrapperMessagingChannel.channelIdentityId,
        };
      case 'whatsapp360dialog':
        return {
          channelType: 'whatsapp360dialog' as const,
          id: conversationWrapperMessagingChannel.channelId as number,
          whatsAppPhoneNumber:
            conversationWrapperMessagingChannel.channelWhatsAppPhoneNumber,
          channelIdentityId:
            conversationWrapperMessagingChannel.channelIdentityId,
        };
      case 'facebook':
        return {
          channelType: 'facebook' as const,
          pageId: conversationWrapperMessagingChannel.pageId,
          channelIdentityId:
            conversationWrapperMessagingChannel.channelIdentityId,
        };
      case 'instagram':
        return {
          channelType: 'instagram' as const,
          pageId: conversationWrapperMessagingChannel.instagramPageId,
          channelIdentityId:
            conversationWrapperMessagingChannel.channelIdentityId,
        };
      case 'line':
        return {
          channelType: 'line' as const,
          channelIdentityId:
            conversationWrapperMessagingChannel.channelIdentityId,
        };
      case 'telegram':
        return {
          channelType: 'telegram' as const,
          telegramBotId: conversationWrapperMessagingChannel.telegramBotId,
          channelIdentityId:
            conversationWrapperMessagingChannel.channelIdentityId,
        };
      case 'wechat':
        return {
          channelType: 'wechat' as const,
          appId: conversationWrapperMessagingChannel.openid,
          channelIdentityId:
            conversationWrapperMessagingChannel.channelIdentityId,
        };
      case 'viber': {
        return {
          channelType: 'viber' as const,
          channelIdentityId:
            conversationWrapperMessagingChannel.channelIdentityId,
        };
      }
      case 'sms':
        return {
          channelType: 'sms' as const,
        };
      case 'note':
        return {
          channelType: 'note' as const,
        };
      case 'web':
        return {
          channelType: 'web' as const,
        };
      case 'email':
        return {
          channelType: 'email' as const,
        };
      default:
        return exhaustiveGuard(
          channelType,
          `${channelType} is not implemented`,
        );
    }
    // sms id should be twilioAccountId which doesn't existed on ApiMessage
  };

// get full name that takes care of cases where first or last name is empty or fallback to another value
export const getFullName = ({
  firstName,
  lastName,
  fallback,
}: {
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  fallback: string;
}) => {
  /*
   * Check firstName or lastName exists
   * if neither are defined fallback to untitled
   */
  let fullName = '';
  // check first name to avoid space in the front if only lastname exists
  if (firstName) {
    fullName = `${firstName?.trim() || ''} ${lastName?.trim() || ''}`.trim();
  } else {
    fullName = lastName ? lastName.trim() : fallback;
  }

  return fullName.trim() !== '' ? fullName : fallback;
};
